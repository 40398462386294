import tenantRouterOptions from "#build/tenant-router.options";
import routerOptions0 from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kgc3fyt77ex6ozwgwylhycz4ea/node_modules/nuxt/dist/pages/runtime/router.options";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...tenantRouterOptions,
...routerOptions0,
}
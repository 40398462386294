
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "fab06d57-6110-4b5a-92ca-1ab7acdf8c8b"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)

import validate from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kgc3fyt77ex6ozwgwylhycz4ea/node_modules/nuxt/dist/pages/runtime/validate.js";
import set_45app_45global from "/home/federico/Documents/Work/powerflow/powerflow-site/middleware/setApp.global.ts";
import manifest_45route_45rule from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kgc3fyt77ex6ozwgwylhycz4ea/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  set_45app_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-subdomain": () => import("/home/federico/Documents/Work/powerflow/powerflow-site/middleware/auth-subdomain.ts"),
  auth: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_@opentelemetry+api@1.8.0_encoding@0.1.13_next-auth@4.21.1_next@13.5_ox7tgzxryvblarmxqvaxjzlbl4/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}
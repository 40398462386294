import { default as _91id_939NbBKoJjAkMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/pages/[site]/[model]/[id].vue?macro=true";
import { default as indexDaxL3WDq2bMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/pages/[site]/[model]/index.vue?macro=true";
import { default as indexAI3kYDpNnfMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/pages/[site]/index.vue?macro=true";
import { default as _91id_934RbqRRO5otMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/[model]/[id].vue?macro=true";
import { default as index3JmS7kb9jgMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/[model]/index.vue?macro=true";
import { default as _91model_93EYf4GRuJZjMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/[model].vue?macro=true";
import { default as chatbotUcfUsKufyeMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/chatbot.vue?macro=true";
import { default as _91id_931LmETMDaImMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/documents/[id].vue?macro=true";
import { default as indexjr9imwI4A4Meta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/documents/index.vue?macro=true";
import { default as documentsjvBpcRA0K3Meta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/documents.vue?macro=true";
import { default as _91id_93kTV6lnbkJMMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/impostazioni/[id].vue?macro=true";
import { default as index8ZdS5zAGuYMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/impostazioni/index.vue?macro=true";
import { default as impostazioniUM5iW4qGmZMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/impostazioni.vue?macro=true";
import { default as indexfYkwTndVTBMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/index.vue?macro=true";
import { default as _91id_932cyoU3DnrMMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/public_documents/[id].vue?macro=true";
import { default as indexcE4G3KjufIMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/public_documents/index.vue?macro=true";
import { default as public_documentsDaTn5PwFIgMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/public_documents.vue?macro=true";
import { default as indextxgKoXeotfMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/pages/index.vue?macro=true";
import { default as loginn0CJB7LlQSMeta } from "/home/federico/Documents/Work/powerflow/powerflow-site/pages/login.vue?macro=true";
export default [
  {
    name: _91id_939NbBKoJjAkMeta?.name ?? "site-model-id",
    path: _91id_939NbBKoJjAkMeta?.path ?? "/:site()/:model()/:id()",
    meta: _91id_939NbBKoJjAkMeta || {},
    alias: _91id_939NbBKoJjAkMeta?.alias || [],
    redirect: _91id_939NbBKoJjAkMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/pages/[site]/[model]/[id].vue").then(m => m.default || m)
  },
  {
    name: indexDaxL3WDq2bMeta?.name ?? "site-model",
    path: indexDaxL3WDq2bMeta?.path ?? "/:site()/:model()",
    meta: indexDaxL3WDq2bMeta || {},
    alias: indexDaxL3WDq2bMeta?.alias || [],
    redirect: indexDaxL3WDq2bMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/pages/[site]/[model]/index.vue").then(m => m.default || m)
  },
  {
    name: indexAI3kYDpNnfMeta?.name ?? "site",
    path: indexAI3kYDpNnfMeta?.path ?? "/:site()",
    meta: indexAI3kYDpNnfMeta || {},
    alias: indexAI3kYDpNnfMeta?.alias || [],
    redirect: indexAI3kYDpNnfMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/pages/[site]/index.vue").then(m => m.default || m)
  },
  {
    name: _91model_93EYf4GRuJZjMeta?.name ?? undefined,
    path: _91model_93EYf4GRuJZjMeta?.path ?? "/:site()/procedure/:model()",
    meta: _91model_93EYf4GRuJZjMeta || {},
    alias: _91model_93EYf4GRuJZjMeta?.alias || [],
    redirect: _91model_93EYf4GRuJZjMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/[model].vue").then(m => m.default || m),
    children: [
  {
    name: _91id_934RbqRRO5otMeta?.name ?? "site-procedure-model-id",
    path: _91id_934RbqRRO5otMeta?.path ?? ":id()",
    meta: _91id_934RbqRRO5otMeta || {},
    alias: _91id_934RbqRRO5otMeta?.alias || [],
    redirect: _91id_934RbqRRO5otMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/[model]/[id].vue").then(m => m.default || m)
  },
  {
    name: index3JmS7kb9jgMeta?.name ?? "site-procedure-model",
    path: index3JmS7kb9jgMeta?.path ?? "",
    meta: index3JmS7kb9jgMeta || {},
    alias: index3JmS7kb9jgMeta?.alias || [],
    redirect: index3JmS7kb9jgMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/[model]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: chatbotUcfUsKufyeMeta?.name ?? "site-procedure-chatbot",
    path: chatbotUcfUsKufyeMeta?.path ?? "/:site()/procedure/chatbot",
    meta: chatbotUcfUsKufyeMeta || {},
    alias: chatbotUcfUsKufyeMeta?.alias || [],
    redirect: chatbotUcfUsKufyeMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/chatbot.vue").then(m => m.default || m)
  },
  {
    name: documentsjvBpcRA0K3Meta?.name ?? undefined,
    path: documentsjvBpcRA0K3Meta?.path ?? "/:site()/procedure/documents",
    meta: documentsjvBpcRA0K3Meta || {},
    alias: documentsjvBpcRA0K3Meta?.alias || [],
    redirect: documentsjvBpcRA0K3Meta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/documents.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_931LmETMDaImMeta?.name ?? "site-procedure-documents-id",
    path: _91id_931LmETMDaImMeta?.path ?? ":id()",
    meta: _91id_931LmETMDaImMeta || {},
    alias: _91id_931LmETMDaImMeta?.alias || [],
    redirect: _91id_931LmETMDaImMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/documents/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjr9imwI4A4Meta?.name ?? "site-procedure-documents",
    path: indexjr9imwI4A4Meta?.path ?? "",
    meta: indexjr9imwI4A4Meta || {},
    alias: indexjr9imwI4A4Meta?.alias || [],
    redirect: indexjr9imwI4A4Meta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/documents/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: impostazioniUM5iW4qGmZMeta?.name ?? undefined,
    path: impostazioniUM5iW4qGmZMeta?.path ?? "/:site()/procedure/impostazioni",
    meta: impostazioniUM5iW4qGmZMeta || {},
    alias: impostazioniUM5iW4qGmZMeta?.alias || [],
    redirect: impostazioniUM5iW4qGmZMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/impostazioni.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93kTV6lnbkJMMeta?.name ?? "site-procedure-impostazioni-id",
    path: _91id_93kTV6lnbkJMMeta?.path ?? ":id()",
    meta: _91id_93kTV6lnbkJMMeta || {},
    alias: _91id_93kTV6lnbkJMMeta?.alias || [],
    redirect: _91id_93kTV6lnbkJMMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/impostazioni/[id].vue").then(m => m.default || m)
  },
  {
    name: index8ZdS5zAGuYMeta?.name ?? "site-procedure-impostazioni",
    path: index8ZdS5zAGuYMeta?.path ?? "",
    meta: index8ZdS5zAGuYMeta || {},
    alias: index8ZdS5zAGuYMeta?.alias || [],
    redirect: index8ZdS5zAGuYMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/impostazioni/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexfYkwTndVTBMeta?.name ?? "site-procedure",
    path: indexfYkwTndVTBMeta?.path ?? "/:site()/procedure",
    meta: indexfYkwTndVTBMeta || {},
    alias: indexfYkwTndVTBMeta?.alias || [],
    redirect: indexfYkwTndVTBMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/index.vue").then(m => m.default || m)
  },
  {
    name: public_documentsDaTn5PwFIgMeta?.name ?? undefined,
    path: public_documentsDaTn5PwFIgMeta?.path ?? "/:site()/procedure/public_documents",
    meta: public_documentsDaTn5PwFIgMeta || {},
    alias: public_documentsDaTn5PwFIgMeta?.alias || [],
    redirect: public_documentsDaTn5PwFIgMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/public_documents.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_932cyoU3DnrMMeta?.name ?? "site-procedure-public_documents-id",
    path: _91id_932cyoU3DnrMMeta?.path ?? ":id()",
    meta: _91id_932cyoU3DnrMMeta || {},
    alias: _91id_932cyoU3DnrMMeta?.alias || [],
    redirect: _91id_932cyoU3DnrMMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/public_documents/[id].vue").then(m => m.default || m)
  },
  {
    name: indexcE4G3KjufIMeta?.name ?? "site-procedure-public_documents",
    path: indexcE4G3KjufIMeta?.path ?? "",
    meta: indexcE4G3KjufIMeta || {},
    alias: indexcE4G3KjufIMeta?.alias || [],
    redirect: indexcE4G3KjufIMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/procedure/pages/[site]/procedure/public_documents/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indextxgKoXeotfMeta?.name ?? "index",
    path: indextxgKoXeotfMeta?.path ?? "/",
    meta: indextxgKoXeotfMeta || {},
    alias: indextxgKoXeotfMeta?.alias || [],
    redirect: indextxgKoXeotfMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginn0CJB7LlQSMeta?.name ?? "login",
    path: loginn0CJB7LlQSMeta?.path ?? "/login",
    meta: loginn0CJB7LlQSMeta || {},
    alias: loginn0CJB7LlQSMeta?.alias || [],
    redirect: loginn0CJB7LlQSMeta?.redirect,
    component: () => import("/home/federico/Documents/Work/powerflow/powerflow-site/pages/login.vue").then(m => m.default || m)
  }
]
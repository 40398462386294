
      import { useRequestURL } from 'nuxt/app';

      const dynamicRoutePrefix = '/:site()';

      const rewritePrefixRoute = (route, prefix) => {
        if (route.path.startsWith(prefix)) {
          return {
            ...route,
            path: route.path.replace(prefix, ""),
          };
        }
        return route;
      }

      const ignoreDynamicRoute = (route) => !route.path.startsWith(dynamicRoutePrefix);

      export default {
        routes: (routes) => {
          const { hostname } = useRequestURL();

          const customDomains = {};
          const customDomainRoute = customDomains[hostname];
          if (customDomainRoute) {
            return routes
              .filter(ignoreDynamicRoute)
              .filter(route => route.path.startsWith('/' + customDomainRoute))
              .map((route) => rewritePrefixRoute(route, '/' + customDomainRoute));
          }

          const rootDomain = ["power-flow.io","localhost"].find(domain => hostname.endsWith(domain));

          if (!rootDomain) {
            return routes;
          }
          if (hostname === rootDomain) {
            return routes.filter(ignoreDynamicRoute);
          }

          const sites = new Set([]);

          const tenant = hostname.substring(0, hostname.indexOf(rootDomain) - 1);

          if (sites.has(tenant)) {
            return routes
              .filter(ignoreDynamicRoute)
              .map((route) => rewritePrefixRoute(route, '/' + tenant));
          }

          return routes
            .map((route) => rewritePrefixRoute(route, dynamicRoutePrefix))
        },
      };
      
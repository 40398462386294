import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAutoComplete, LazyCalendar, LazyCascadeSelect, LazyCheckbox, LazyChips, LazyColorPicker, LazyDropdown, LazyFloatLabel, LazyIconField, LazyInputGroup, LazyInputGroupAddon, LazyInputIcon, LazyInputMask, LazyInputNumber, LazyInputOtp, LazyInputSwitch, LazyInputText, LazyKnob, LazyListbox, LazyMultiSelect, LazyPassword, LazyRadioButton, LazyRating, LazySelectButton, LazySlider, LazyTextarea, LazyToggleButton, LazyTreeSelect, LazyTriStateCheckbox, LazyButton, LazyButtonGroup, LazySpeedDial, LazySplitButton, LazyColumn, LazyRow, LazyColumnGroup, LazyDataTable, LazyDataView, LazyDataViewLayoutOptions, LazyOrderList, LazyOrganizationChart, LazyPaginator, LazyPickList, LazyTree, LazyTreeTable, LazyTimeline, LazyVirtualScroller, LazyAccordion, LazyAccordionTab, LazyCard, LazyDeferredContent, LazyDivider, LazyFieldset, LazyPanel, LazyScrollPanel, LazySplitter, LazySplitterPanel, LazyStepper, LazyStepperPanel, LazyTabView, LazyTabPanel, LazyToolbar, LazyConfirmPopup, LazyDialog, LazyDynamicDialog, LazyOverlayPanel, LazySidebar, LazyFileUpload, LazyBreadcrumb, LazyDock, LazyMenu, LazyMenubar, LazyMegaMenu, LazyPanelMenu, LazySteps, LazyTabMenu, LazyTieredMenu, LazyMessage, LazyInlineMessage, LazyToast, LazyCarousel, LazyGalleria, LazyImage, LazyAvatar, LazyAvatarGroup, LazyBadge, LazyBlockUI, LazyChip, LazyInplace, LazyMeterGroup, LazyScrollTop, LazySkeleton, LazyProgressBar, LazyProgressSpinner, LazyTag, LazyTerminal } from '#components'
const lazyGlobalComponents = [
  ["AutoComplete", LazyAutoComplete],
["Calendar", LazyCalendar],
["CascadeSelect", LazyCascadeSelect],
["Checkbox", LazyCheckbox],
["Chips", LazyChips],
["ColorPicker", LazyColorPicker],
["Dropdown", LazyDropdown],
["FloatLabel", LazyFloatLabel],
["IconField", LazyIconField],
["InputGroup", LazyInputGroup],
["InputGroupAddon", LazyInputGroupAddon],
["InputIcon", LazyInputIcon],
["InputMask", LazyInputMask],
["InputNumber", LazyInputNumber],
["InputOtp", LazyInputOtp],
["InputSwitch", LazyInputSwitch],
["InputText", LazyInputText],
["Knob", LazyKnob],
["Listbox", LazyListbox],
["MultiSelect", LazyMultiSelect],
["Password", LazyPassword],
["RadioButton", LazyRadioButton],
["Rating", LazyRating],
["SelectButton", LazySelectButton],
["Slider", LazySlider],
["Textarea", LazyTextarea],
["ToggleButton", LazyToggleButton],
["TreeSelect", LazyTreeSelect],
["TriStateCheckbox", LazyTriStateCheckbox],
["Button", LazyButton],
["ButtonGroup", LazyButtonGroup],
["SpeedDial", LazySpeedDial],
["SplitButton", LazySplitButton],
["Column", LazyColumn],
["Row", LazyRow],
["ColumnGroup", LazyColumnGroup],
["DataTable", LazyDataTable],
["DataView", LazyDataView],
["DataViewLayoutOptions", LazyDataViewLayoutOptions],
["OrderList", LazyOrderList],
["OrganizationChart", LazyOrganizationChart],
["Paginator", LazyPaginator],
["PickList", LazyPickList],
["Tree", LazyTree],
["TreeTable", LazyTreeTable],
["Timeline", LazyTimeline],
["VirtualScroller", LazyVirtualScroller],
["Accordion", LazyAccordion],
["AccordionTab", LazyAccordionTab],
["Card", LazyCard],
["DeferredContent", LazyDeferredContent],
["Divider", LazyDivider],
["Fieldset", LazyFieldset],
["Panel", LazyPanel],
["ScrollPanel", LazyScrollPanel],
["Splitter", LazySplitter],
["SplitterPanel", LazySplitterPanel],
["Stepper", LazyStepper],
["StepperPanel", LazyStepperPanel],
["TabView", LazyTabView],
["TabPanel", LazyTabPanel],
["Toolbar", LazyToolbar],
["ConfirmPopup", LazyConfirmPopup],
["Dialog", LazyDialog],
["DynamicDialog", LazyDynamicDialog],
["OverlayPanel", LazyOverlayPanel],
["Sidebar", LazySidebar],
["FileUpload", LazyFileUpload],
["Breadcrumb", LazyBreadcrumb],
["Dock", LazyDock],
["Menu", LazyMenu],
["Menubar", LazyMenubar],
["MegaMenu", LazyMegaMenu],
["PanelMenu", LazyPanelMenu],
["Steps", LazySteps],
["TabMenu", LazyTabMenu],
["TieredMenu", LazyTieredMenu],
["Message", LazyMessage],
["InlineMessage", LazyInlineMessage],
["Toast", LazyToast],
["Carousel", LazyCarousel],
["Galleria", LazyGalleria],
["Image", LazyImage],
["Avatar", LazyAvatar],
["AvatarGroup", LazyAvatarGroup],
["Badge", LazyBadge],
["BlockUI", LazyBlockUI],
["Chip", LazyChip],
["Inplace", LazyInplace],
["MeterGroup", LazyMeterGroup],
["ScrollTop", LazyScrollTop],
["Skeleton", LazySkeleton],
["ProgressBar", LazyProgressBar],
["ProgressSpinner", LazyProgressSpinner],
["Tag", LazyTag],
["Terminal", LazyTerminal],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

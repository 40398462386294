import revive_payload_client_BwNrcr1Jxj from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kgc3fyt77ex6ozwgwylhycz4ea/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GyQ2jepib7 from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kgc3fyt77ex6ozwgwylhycz4ea/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KK7eINKrdd from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kgc3fyt77ex6ozwgwylhycz4ea/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_gUqEK2zOk6 from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kgc3fyt77ex6ozwgwylhycz4ea/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_YVVqstX6hj from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kgc3fyt77ex6ozwgwylhycz4ea/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/home/federico/Documents/Work/powerflow/powerflow-site/.nuxt/components.plugin.mjs";
import prefetch_client_ngDv2y3p8r from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kgc3fyt77ex6ozwgwylhycz4ea/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import primevue_plugin_egKpok8Auk from "/home/federico/Documents/Work/powerflow/powerflow-site/.nuxt/primevue-plugin.mjs";
import plugin_client_rA8UXo6HRw from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt-primevue@0.3.1_rollup@4.18.0_vue@3.4.27/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import plugin_OcRFqIuVud from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt-multi-tenancy@1.2.3_rollup@4.18.0/node_modules/nuxt-multi-tenancy/dist/runtime/plugin.mjs";
import plugin_aZz0QAsUuR from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/@sidebase+nuxt-auth@0.7.2_@opentelemetry+api@1.8.0_encoding@0.1.13_next-auth@4.21.1_next@13.5_ox7tgzxryvblarmxqvaxjzlbl4/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_iwc8uqXB3v from "/home/federico/Documents/Work/powerflow/powerflow-site/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_kgc3fyt77ex6ozwgwylhycz4ea/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_BwNrcr1Jxj,
  unhead_GyQ2jepib7,
  router_KK7eINKrdd,
  payload_client_gUqEK2zOk6,
  check_outdated_build_client_YVVqstX6hj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ngDv2y3p8r,
  primevue_plugin_egKpok8Auk,
  plugin_client_rA8UXo6HRw,
  plugin_OcRFqIuVud,
  plugin_aZz0QAsUuR,
  chunk_reload_client_iwc8uqXB3v
]